<template>
  <span class="input-inline-text" v-class-mod:input-inline-text="{ isEditable, isActive, isFocus }">
    <span
      :contenteditable="canEdit"
      :tabindex="canBeFocused ? 0 : -1"
      class="input-inline-text__input"
      @click="
        () => {
          if (isEditable) {
            isActive = true;
          }
        }
      "
      @mouseenter="
        () => {
          if (isEditable) {
            isActive = true;
          }
        }
      "
      @blur="
        () => {
          if (isActive) {
            update();
          }
          isActive = false;
          isFocus = false;
        }
      "
      @focus="
        () => {
          if (isEditable && !isFocus) {
            isActive = true;
            isFocus = true;
            setInputFocus();
          }
        }
      "
      @keydown.enter.prevent.stop="
        () => {
          update();
          isActive = false;
          isFocus = false;
        }
      "
      @keydown.esc.stop="
        () => {
          isActive = false;
          reset();
        }
      "
      @keyup="checkRules"
      ref="input"
    >
      {{ text }}
    </span>
    <span class="input-inline-text__placeholder">
      {{ placeholder || "-" }}
    </span>

    <loader-bars v-if="isLoading" />
    <input-feedback v-if="feedback" v-bind="feedback" class="input-inline-text__feedback" />
  </span>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from "vue";

import { delayFn, isHtmlElem } from "@horizon56/utils";

import InputFeedback, { FeedbackProps, FeedbackReturnType } from "@/components/inputs/input-feedback.vue";
import LoaderBars from "@/components/loaders/loader-bars.vue";

export type InputInlineTextProps = {
  text: string;
  placeholder: string;
  isEditable?: boolean;
  setFocus?: boolean;
  trim?: boolean;
  action?: (t: string) => FeedbackReturnType;
};

const props = defineProps<InputInlineTextProps>();

const input = ref<HTMLElement | null>();
const isActive = ref(false);
const isLoading = ref(false);
const isFocus = ref(false);
const feedback = ref<FeedbackProps>();

const canBeFocused = computed(() => props?.isEditable && typeof props.action === "function");
const canEdit = computed(() => props?.isEditable && typeof props.action === "function" && isActive.value);

const update = async () => {
  if (input.value && canEdit.value && props.action) {
    isLoading.value = true;

    input.value.blur();
    const value = props.trim ? input.value.innerText.trim() : input.value.innerText;
    if (value !== props.text) {
      const result = await props.action(value);
      if (result && "status" in result) {
        feedback.value = {
          ...result,
          callback: () => {
            feedback.value = undefined;
            result.callback?.();
          },
        };
      }
      if (result && result.status === "success") {
        window.setTimeout(reset, 1000);
      } else {
        reset();
      }
    }
    isLoading.value = false;
  }
};

const checkRules = () => {
  if (props.trim && input.value && input.value.innerText !== input.value.innerText.trimStart()) {
    input.value.innerText = input.value.innerText.trimStart();
  }
};

const reset = () => {
  if (input.value && document.activeElement !== input.value) {
    window.requestAnimationFrame(() => {
      if (input.value) {
        input.value.innerText = props.text;
      }
    });
  }
};

defineExpose({ reset });

const setInputFocus = async () => {
  if (!props.isEditable) {
    return;
  }
  isActive.value = true;
  await delayFn(() => isHtmlElem(input.value));
  if (!isHtmlElem(input.value)) {
    return;
  }

  const range = document.createRange();
  range.selectNodeContents(input.value);
  range.collapse(false);

  const sel = window.getSelection();
  sel?.removeAllRanges();
  sel?.addRange(range);
};

watch(
  () => props.setFocus,
  async (is) => {
    if (is) {
      setInputFocus();
    }
  },
  { immediate: true },
);
</script>

<style lang="scss" scoped>
.input-inline-text {
  position: relative;
  &:after {
    content: "";
    left: 0;
    right: 0;
    bottom: -3px;
    position: absolute;
    border-bottom: 0px solid var(--green-500);
  }
  &--isFocus:after {
    border-bottom-width: 3px;
    bottom: -4px;
    transition:
      border-bottom-width var(--app-transition),
      bottom var(--app-transition);
  }
  &--isEditable:not(#{&}--isFocus):hover:after {
    border-bottom-width: 1px;
  }
  &--isEditable #{&}__input {
    pointer-events: all;
    &:empty {
      position: absolute;
      @include position-corner;
    }
  }
  &__input {
    outline: none;
    position: relative;
    z-index: 2;
  }
  &__placeholder {
    opacity: 0.4;
    z-index: 1;
    position: relative;
    cursor: pointer;
  }
  &__feedback {
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    white-space: nowrap;
    left: calc(100% + var(--app-spacing-size-small));
  }
  &__input:not(:empty) + #{&}__placeholder {
    display: none;
  }
}
</style>
